<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > {{ $t('council.memberSpecialist.title') }}</div>
        </div>
    </div>
    <div class="container mb-5" dir="rtl">
        <p class="mt-5 mb-4">
            يتوفر المجلس، قصد تحقيق أهدافه، على لجان متخصصة تتكون كل واحدة منها من ثلاثين 30 إلى ثلاثة وأربعين 43 عضوا.
        </p>
        <ul>
            <li>لجنة التربية والتكوين والتعليم العالي والبحث العلمي وتعزيز قدرات الشباب</li>
            <li>لجنة التشغيل والمقاولاتية والابتكار واقتصاد المعرفة</li>
            <li>لجنة المواطنة والتطوع والحياة الجمعوية ومشاركة الشباب في الحياة العامة</li>
            <li>لجنة الثقافة والرياضة والترفيه والسياحة وحركية الشباب</li>
            <li>لجنة الاعلام والاتصال</li>
            <li>لجنة البيئة والتنمية المستدامة</li>
            <li>اللجنة الاجتماعية والتضامن ووقاية الشباب من الآفات الإجتماعية وحمايتهم</li>
            <li>لجنة التعاون والعلاقات الدولية</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Specialized_committees"
    }
</script>

<style scoped>
    ul li{
        font-size: 14px!important;
        color: #2a271f;
        line-height: 2.5;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
        text-align: justify;
        text-justify: inter-word;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>