<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('council.title') }}  > {{  $t('council.memberCouncil.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 mb-3" dir="rtl">
        <div class="container mt-5 text-center mb-5" dir="rtl">
            <h1>
                الصفحة في طور الإنجاز !!!
            </h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: "membersCouncil"
    }
</script>

<style scoped>
    @media (max-width: 900px) {
        .view_mobile{
            display: block!important;
        }
        .embed_pdf{
            display: none;
        }
    }
    @media (min-width: 900px) {
        .view_mobile{
            display: none!important;
        }
        .embed_pdf{
            display: block!important;
        }
    }
</style>