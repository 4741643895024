<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('events.title') }} > {{ $t('events.images.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="gallery text-center mt-5 mb-5">
            <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                <Slide v-for="item in items" :key="item.id">
                    <div class="carousel__item">
                        <a :href="item.thumb" target="_blank" style="text-decoration: none">
                            <img className="img-thumbnail" :src="item.thumb" alt="">
                        </a>
                    </div>
                </Slide>
            </Carousel>
            <br><br>
            <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel" :autoplay="8000">
                <Slide v-for="item in items" :key="item.id">
                    <div class="carousel__item" @click="slideTo(item - 1)">
                        <a :href="item.thumb" target="_blank" style="text-decoration: none;">
                            <img className="img-thumbnail" :src="item.thumb" style="min-height: 80px!important;" alt="">
                        </a>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                    <!--<Pagination />-->
                </template>
            </Carousel>
        </div>
    </div>

</template>

<script>
    import { defineComponent } from 'vue'
    import { Carousel, Slide, Navigation,Pagination } from 'vue3-carousel'

    export default {
        name: "gallery",
        components: {
            Carousel,
            Slide,
            Navigation,
            Pagination
        },
        data: () => ({
            currentSlide: 0,
            items:[
                {
                    id : "1",
                    thumb : "/images/pictures/1.png"
                },
                {
                    id : "2",
                    thumb : "/images/pictures/2.jpg"
                },
                {
                    id : "3",
                    thumb : "/images/pictures/3.jpg"
                },
                {
                    id : "4",
                    thumb : "/images/pictures/4.jpg"
                },
                {
                    id : "5",
                    thumb : "/images/pictures/5.jpg"
                },
                {
                    id : "6",
                    thumb : "/images/pictures/6.jpg"
                },
                {
                    id : "7",
                    thumb : "/images/pictures/7.jpg"
                },
                {
                    id : "8",
                    thumb : "/images/pictures/8.jpg"
                },
                {
                    id : "9",
                    thumb : "/images/pictures/9.jpg"
                },
                {
                    id : "10",
                    thumb : "/images/pictures/10.jpg"
                },
                {
                    id : "11",
                    thumb : "/images/pictures/11.jpg"
                },
                {
                    id : "12",
                    thumb : "/images/pictures/12.jpg"
                },
                {
                    id : "13",
                    thumb : "/images/pictures/13.jpg"
                },
                {
                    id : "14",
                    thumb : "/images/pictures/14.jpg"
                },
                {
                    id : "15",
                    thumb : "/images/pictures/15.jpg"
                },
                {
                    id : "16",
                    thumb : "/images/pictures/16.jpg"
                },
                {
                    id : "17",
                    thumb : "/images/pictures/17.jpg"
                },
                {
                    id : "18",
                    thumb : "/images/pictures/18.jpg"
                },
                {
                    id : "19",
                    thumb : "/images/pictures/19.jpg"
                },
                {
                    id : "20",
                    thumb : "/images/pictures/20.jpg"
                },
                {
                    id : "21",
                    thumb : "/images/pictures/21.jpg"
                },
                {
                    id : "22",
                    thumb : "/images/pictures/22.jpg"
                },
                {
                    id : "23",
                    thumb : "/images/pictures/23.jpg"
                },
                {
                    id : "24",
                    thumb : "/images/pictures/24.jpg"
                },
                {
                    id : "25",
                    thumb : "/images/pictures/25.jpg"
                },
                {
                    id : "26",
                    thumb : "/images/pictures/26.jpg"
                },
                {
                    id : "27",
                    thumb : "/images/pictures/27.jpg"
                },
                {
                    id : "28",
                    thumb : "/images/pictures/28.jpg"
                },
                {
                    id : "29",
                    thumb : "/images/pictures/29.jpg"
                },
                {
                    id : "30",
                    thumb : "/images/pictures/30.jpg"
                },
            ]
        }),
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            },
            slideTo(val) {
                this.currentSlide = val
            },
        }
    }
</script>

<style scoped>
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }
    .back-gray p{
        line-height: 200%;
    }
    .carousel * {
        box-sizing: border-box;
    }
    #thumbnails .carousel__item{
        background-image: linear-gradient(to right, rgb(43, 64, 13), rgb(255, 252, 249));
    }
    .carousel__item {
        min-height: 140px;
        width: 100%;
        /*color: #fff;*/
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel__item img{
        max-height: 400px;
    }
</style>