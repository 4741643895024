<template>
    <div class="fade-in wait_circle d-none" ></div>
</template>

<script>
    export default {
        name: "WaitPage"
    }
</script>

<style scoped>

    .wait_circle {
        position:   fixed;
        z-index:    2000;
        top:        0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.3) url("../../public/animation/loading.gif") 50% 50%  no-repeat;
    }
</style>