<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div  class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('welcome.advertisements.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <!--<div class="list mt-4 mb-4">اعلانات المجلس</div>-->
        <div class="advertisements mt-4">
            <h4><span>|</span>اعلانات المجلس</h4>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-12 col-md-6" v-for="advertisement in getItems">
                <div class="list_article">
                    <div class="rec"></div>
                    <router-link :to="{name: 'Detail_article', params: {id: advertisement[1].id}}" class="title_article_all" style="text-decoration: none!important;">
                        {{ advertisement[1].title }}
                    </router-link>
                </div>
                <span style="font-size: 13px!important;"> | {{ date_article(advertisement[1].date_article) }}  </span>
            </div>
            <div class="mb-5 mt-2"></div>
            <hr>
            <paginate
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :prev-text="'السابق'"
                    :next-text="'التالي'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import Paginate from 'vuejs-paginate-next';
    export default {
        data(){
            return {
                //url_back:'https://backend.csj.gov.dz/uploads/',
                url_back:'https://backend.csj.gov.dz/uploads/',
                perPage: 10,
                currentPage: 1,
                advertisements:this.advertisements
            }
        },
        name: "advertisements",
        components:{
            paginate: Paginate
        },
        created(){
            this.getAdvertisements();
        },
        methods:{
            async getAdvertisements(){
                let url = 'https://backend.csj.gov.dz/api/advertisements';
                await axios.get(url).then(response =>{
                    $('.wait_circle').addClass('d-none');
                    this.advertisements = response.data.advertisements;
                }).catch(error => {
                    console.log(error);
                });
            },
            clickCallback: function (pageNum) {
                this.currentPage = Number(pageNum);
            },
            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            }
        },
        computed:{
            getItems: function() {
                let advertisements = this.advertisements;
                let current = this.currentPage * this.perPage;
                let start = current - this.perPage;

                var result = [];
                for(var i in advertisements){
                    result.push([i, advertisements [i]]);
                }
                return result.slice(start, current);
            },
            getPageCount: function() {
                let advertisements = this.advertisements;
                var result = [];
                for(var i in advertisements)
                    result.push([i, advertisements [i]]);
                return Math.ceil(result.length / this.perPage);
            }
        },
        mounted(){

        }
    }
</script>

<style scoped>
    .advertisements{
        font-family: DroidArabic!important;
    }
    .advertisements h4{
        margin-bottom: 25px;
    }
    .advertisements h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .title_article {
        font-size: 1rem;
        line-height: calc(30/16 * 1rem);
        margin-bottom: calc(10/16 * 1rem);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white!important;
    }
    .title_article_all{
        font-size: 1.1rem;
        line-height: calc(30/16 * 1rem);
        margin-bottom: calc(10/16 * 1rem);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4a4a4a !important;
    }
    .list_article{
        display: flex;
        align-items: center;
        /*margin-bottom: 10px;*/
        color: #1b1c26;
        margin-top: 40px;
    }
    .rec{
        height: 8px;
        max-width: 40px;
        min-width: 20px;
        background-color:#a72221 ;
        /*margin-right: 10px;*/
        margin-left: 20px;
        margin-bottom: 5px;
    }
</style>