<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('welcome.lastActivitiesPresident') }} </div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="list mt-4 mb-4">أحدث نشاطات الرئيس</div>
        <div class="row last_article">
            <div class="col-md-6 mt-4 mt-md-0" v-for="item in latestArticles" style="position: relative;">
                <img class="img_latest" :src="url_back +'images/head/'+ item.img_cover" alt="">
                <div class="latest_overlay"></div>
                <div class="content_latest">
                    <router-link :to="{name: 'Detail_article', params: {id: item.id}}">
                        <div class="title_article">{{ item.title }}</div>
                        <div class="date_latest">
                            {{ date_article(item.date_article) }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="list mt-4 mb-4">كل نشاطات الرئيس</div>

            <div class="col-12 col-md-6" v-for="article in getItems">
                <div class="list_article">
                    <div class="rec"></div>
                    <router-link :to="{name: 'Detail_article', params: {id: article[1].id}}" class="title_article_all" style="text-decoration: none!important;">
                        {{ article[1].title }}
                    </router-link>
                </div>
                <span style="font-size: 13px!important;"> | {{ date_article(article[1].date_article) }}  </span>
            </div>
            <div class="mb-5 mt-2"></div>
            <hr>
            <paginate
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :prev-text="'السابق'"
                    :next-text="'التالي'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import Paginate from 'vuejs-paginate-next';

    export default {
        data(){
            return {
                latestArticles:Array,
                url_back:'https://backend.csj.gov.dz/uploads/',
                //url_back:'https://backend.csj.gov.dz/uploads/',
                perPage: 10,
                currentPage: 1,
                allArticles:this.allArticles
            }
        },
        name: "Articles",
        components: {
            paginate: Paginate
        },
        created(){
            this.getLatestArticle();
            this.getAllArticles();
        },
        methods:{
            // Latest articles
            async getLatestArticle(){
                $('.wait_circle').removeClass('d-none');
                let url = 'https://backend.csj.gov.dz/api/president/latest_articles';
                //let url = 'https://backend.csj.gov.dz/api/president/latest_articles';
                await axios.get(url).then(response =>{
                    this.latestArticles = response.data.president_latest_articles;
                    // console.log(this.latestArticles);
                }).catch(error => {
                    console.log(error);
                });
            },
            // All articles
            async getAllArticles(){

                //let url = 'https://backend.csj.gov.dz/api/president_articles';
                let url = 'https://backend.csj.gov.dz/api/president_articles';
                await axios.get(url).then(response =>{
                    $('.wait_circle').addClass('d-none');
                    this.allArticles = response.data.president_article_ars;
                }).catch(error => {
                    console.log(error);
                });
            },
            clickCallback: function (pageNum) {
                this.currentPage = Number(pageNum);
            },
            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            },
        },
        computed: {
            getItems: function() {
                let articles = this.allArticles;
                let current = this.currentPage * this.perPage;
                let start = current - this.perPage;

                var result = [];
                for(var i in articles){
                    result.push([i, articles [i]]);
                }
                return result.slice(start, current);
            },
            getPageCount: function() {
                let articles = this.allArticles;
                var result = [];
                for(var i in articles)
                    result.push([i, articles [i]]);
                return Math.ceil(result.length / this.perPage);
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>
    .pagination{
        justify-content: center;
        flex-wrap: wrap;
    }
    .title_article{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none!important;
        color: black!important;
    }
    .list_article{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #1b1c26;
        margin-top: 40px;
    }
    .rec{
        height: 8px;
        max-width: 40px;
        min-width: 20px;
        background-color: #a72221;
        /*margin-right: 10px;*/
        margin-left: 20px;
    }
    .date_latest{
        line-height: calc(22/16 * 1rem);
        font-size: 1rem;
        color: white;
    }
    .content_latest{
        position: absolute!important;
        max-width: 90%!important;
        right: calc(30/16 * 1rem)!important;
        left: unset!important;
        bottom: calc(20/16 * 1rem)!important;
    }
    .content_latest.ltr {
        left: calc(30/16 * 1rem);
        right: unset;
    }
    .content_latest a{
        text-decoration: none;
    }
    .latest_overlay {
        position: absolute!important;
        width: 95%!important;
        height: 100%!important;
        background: linear-gradient(180deg, rgba(77, 87, 98, 0.056) 36.2%, rgba(77, 87, 98, 0.7) 81.24%)!important;
        top: 0!important;
        left: 15px!important;
    }
    .title_article {
        font-size: 1rem;
        line-height: calc(30/16 * 1rem);
        margin-bottom: calc(10/16 * 1rem);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white!important;
    }
    .title_article_all{
        font-size: 1.1rem;
        line-height: calc(30/16 * 1rem);
        margin-bottom: calc(10/16 * 1rem);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4a4a4a !important;
    }
    .img_latest {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        background: rgba(91, 99, 109, 0.46);
        height: 100%;
        width: 100%;
    }
    .list{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--ml-font-size);
        font-weight: 700;
        margin-top: calc(25/16 * var(--default-size-unit));
        margin-bottom: 20px;
    }
    .list::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(18/16 * var(--default-size-unit));
        height: calc(6/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(20/16 * var(--default-size-unit));
    }
    .activities{
        font-family: DroidArabic!important;

    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 40px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
        padding-top: calc(35/22 * var(--default-size-unit))!important;
        padding-bottom: calc(35/22 * var(--default-size-unit))!important;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .details_act p {
        padding: 10px;
        margin-bottom: 10px!important;
    }
</style>