import { createRouter, createWebHistory } from "vue-router"

import Carousel               from '../components/Carousel'
import speechPresident        from '../components/home/presidentState'
import speechPresidentCouncil from '../components/home/presidentCouncil'
import Gallery                from '../components/actions/gallery'
import specializedCommittees  from '../components/council/Specialized_committees'
import membersCouncil         from '../components/council/membersCouncil'
import textLegislation        from '../components/council/textLegislation'
import adminCouncil           from '../components/council/AdminCouncil'
import contact                from '../components/contact'
import presidentCouncil       from '../components/desktop/president'
import subsCouncil            from '../components/desktop/vice_president'
import visions                from '../components/visions/visions'
import data                   from '../components/events/data'
import videos                 from '../components/events/videos'
import advertisements         from '../components/advertisements'

// Activities
import Detail_article              from '../components/details/Detail_article'
import President_articles              from '../components/President_articles'
import Council_articles              from '../components/Council_articles'
import PageNotFound              from '../components/PageNotFound'
// CSJ

import homeComponent from '../components/home/homeComponent'
import aboutCouncil from '../components/council/About_council'
import RolesCouncil from '../components/council/Roles_council'
import VarietyCouncil from '../components/council/Variety_council'

const routes = [
    {
        name: 'speechPresident',
        path: '/speech-president',
        components: {speechPresident}
    },
    {
        name: 'speechPresidentCouncil',
        path: '/speech-president-council',
        components: {speechPresidentCouncil}
    },

    {
        name: 'aboutCouncil',
        path: '/about-council',
        components: {aboutCouncil}
    },
    {
        name: 'RolesCouncil',
        path: '/roles-council',
        components: {RolesCouncil}
    },
    {
        name: 'VarietyCouncil',
        path: '/variety-council',
        components: {VarietyCouncil}
    },
    {
        name: 'Gallery',
        path: '/pictures',
        components: {Gallery}
    },
    {
        name: 'specializedCommittees',
        path: '/specialized-committees',
        components: {specializedCommittees}
    },
    {
        name: 'membersCouncil',
        path: '/members-council',
        components: {membersCouncil}
    },
    {
        name: 'textLegislation',
        path: '/text-legislation',
        components: {textLegislation}
    },
    {
        name: 'adminCouncil',
        path: '/admin-council',
        components: {adminCouncil}
    },
    {
        name: 'contact',
        path: '/contact',
        components: {contact}
    },
    {
        name: 'presidentCouncil',
        path: '/president-council',
        components: {presidentCouncil}
    },
    {
        name: 'subsCouncil',
        path: '/subs-council',
        components: {subsCouncil}
    },
    {
        name: 'visions',
        path: '/visions',
        components: {visions}
    },
    {
        name: 'data',
        path: '/events-data',
        components: {data}
    },
    {
        name: 'videos',
        path: '/events-videos',
        components: {videos}
    },

    {
        name: 'Detail_article',
        path: '/detail-article/:id?',
        components: {Detail_article}
    },
    {
        name: 'President_articles',
        path: '/president_activities',
        components: {President_articles}
    },
    {
        name: 'Council_articles',
        path: '/council_activities',
        components: {Council_articles}
    },
    {
        name: 'advertisements',
        path: '/advertisements',
        components: {advertisements}
    },
    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        components: {PageNotFound}
    },


    {
        path: '/',
        components: {
            Carousel,homeComponent,
        },

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
