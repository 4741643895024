<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > {{ $t('council.textLegislation.title') }}</div>
        </div>
    </div>
    <div class="container mb-5" dir="rtl">
        <div class="row back-gray mt-5">
            <p class="mt-5 mb-2">
                - مرسوم رئاسي رقم 21-416 مؤرخ في 20 ربيع الأول 1443 الموافق 27 أكتوبر 2021 ، يحدد مهام المجلس الأعلى للشباب و تشكيلته و تنظيمه و سيره.
            </p>
            <p class="mb-2">
                - مرسوم رئاسي مؤرخ في 14 ذي القعدة 1443 الموافق 14 جوان 2022 ، يتضمن تعيين رئيس المجلس الأعلى للشباب.
            </p>
            <p class="mb-4">
                - مرسوم تنفيذي رقم 22-402 مؤرخ في 29 ربيع الثاني عام 1444 الموافق 24 نوفمبر 2022 يحدد تنظيم الامانة الإدارية والتقنية للمجلس الاعلى للشباب.
            </p>
            <p>
                - مرسوم رئاسي رقم 23-58 مؤرخ في 3 رجب عام 1444 الموافق 25 جانفي سنة 2023، يتضمن الموافقة على النظام الداخلي للمجلس الأعلى للشباب
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "textLegislation"
    }
</script>

<style scoped>
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
        text-align: justify;
        text-justify: inter-word;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>