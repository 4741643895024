<template>
    <div class="shared">
        <div class="top">
            <img class="logo" src="../assets/republic-stamp.svg" alt="">
            <!--<h6 class="sub-logo" style="font-family: DroidArabic!important;">-->
                <!--<span style="font-family: DroidArabic!important; color: #225b34; font-size: 18px!important;">المجلس الأعلى للشباب</span><br>-->
                <!--<span style="font-family: DroidArabic!important; color: #225b34; font-size: 16px!important;">Higher Council for Youth</span>-->
            <!--</h6>-->
            <img src="../assets/hcy.png" height="50" alt="">
        </div>
        <div id="fixed-navbar">
            <nav class="navbar navbar-expand-lg navbar-dark bg-white">
                <div class="container" dir="rtl">
                    <div class="align-mobile" >
                        <button class="navbar-toggler sa" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" style="background-color: transparent!important;"  aria-expanded="ture" aria-label="Toggle navigation">
                            <svg class="menu" width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="34" height="2.55" fill="#5B636D" />
                                <rect y="5.95" width="34" height="2.55" fill="#5B636D" />
                                <rect y="11.9" width="34" height="2.55" fill="#5B636D" />
                                <rect y="18" width="25.5" height="2.55" fill="#5B636D" />
                            </svg>
                        </button>
                        <router-link class="navbar-brand logo nav-logo"  v-if="lang === 'ar'" to="/" @click="hideMenu" ><img :src="'../images/logo_dark.png'" class="logo_mobile" alt="" > </router-link>
                    </div>
                    <div class="collapse navbar-collapse" id="main_nav">
                        <!--<form class="d-flex" v-if="lang === 'fr' || lang === 'en'">-->
                        <!--<select class="custom-select" v-model="lang" @change="handleChange($event)">-->
                        <!--<option value="en">English</option>-->
                        <!--<option value="fr">Français</option>-->
                        <!--<option value="ar">Arab</option>-->
                        <!--</select>-->
                        <!--</form>-->
                        <ul ref="direction" class="navbar-nav" :class="{ 'ms-auto': direction_ar, 'me-auto': direction }" :dir="rtl" >
                            <li class="nav-item ">
                                <router-link  to="/" class="nav-link " id="accueil" @click="hideMenu" >{{ $t('welcome.title') }}</router-link>
                            </li>
                            <li class="nav-item dropdown ">
                                <a class="nav-link  dropdown-toggle " href="#" data-bs-toggle="dropdown">  {{  $t('council.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <!--<img class="m-0 " src="../assets/biography.jpg" alt="" style="height: 249px!important; width:150px;max-width: 150px; flex-grow: 1;">-->
                                        <div class="flex-container centre" style="flex-grow: 5">

                                            <div class="mt-lg-5 flex-item">
                                                <router-link to="/about-council" class=" dropdown-item mb-10 text-bold " id="presidentWelcome" @click="scrolltop">{{ $t('council.aboutCouncil.title') }}</router-link>
                                                <p class="content_navbar" style="">{{ $t('council.aboutCouncil.content') }}</p>

                                                <router-link to="/specialized-committees" class=" dropdown-item mb-10 text-bold " id="presidentWelcome" @click="scrolltop">{{ $t('council.memberSpecialist.title') }}</router-link>
                                                <p class="content_navbar" style="">{{ $t('council.memberSpecialist.content') }}</p>
                                            </div>

                                            <div class="mt-lg-5 flex-item">
                                                <router-link to="/variety-council" class="dropdown-item mb-10 text-bold" id="biography" @click="scrolltop">{{ $t('council.varietyCouncil.title') }}</router-link>
                                                <p class="content_navbar">{{ $t('council.varietyCouncil.content') }}</p>

                                                <router-link class="dropdown-item" to="/admin-council" id="activities" @click="scrolltop"> {{ $t('council.adminCouncil.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('council.adminCouncil.content') }}</p>
                                            </div>

                                            <div class="mt-lg-5 flex-item">
                                                <router-link class="dropdown-item" to="/members-council" id="activities" @click="scrolltop"> {{ $t('council.memberCouncil.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('council.memberCouncil.content') }}</p>

                                                <router-link to="/text-legislation" class="dropdown-item mb-10 text-bold" id="biography" @click="scrolltop">{{ $t('council.textLegislation.title') }}</router-link>
                                                <p class="content_navbar">{{ $t('council.textLegislation.content') }}</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  {{ $t('desk.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <div class="flex-container centre" style="flex-grow: 5">
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" href="#" id="publicHealthProgram" to="/president-council" @click="scrolltop"> {{ $t('desk.presidentCouncil') }} </router-link>
                                                <p class="content_navbar">تعرفو على رئيس المجلس</p>

                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/subs-council"  id="food" @click="scrolltop"> {{ $t('desk.subsCouncil') }} </router-link>
                                                <p class="content_navbar">نواب المجلس</p>

                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/specialized-committees" id="environment" @click="scrolltop"> {{ $t('desk.specializedCommittees') }} </router-link>
                                                <p class="content_navbar">اللجان المتخصصة</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item" @click="hideMenu">
                                <router-link class="nav-link " to="/visions" id="communique" @click="scrolltop"> {{ $t('visions') }} </router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  {{ $t('events.title') }}  </a>

                                <div class="dropdown-menu m-0 p-0" :style="{right: right +'px'}" dir="rtl" @click="hideMenu">
                                    <div class="m-0 p-0" style="display: flex">
                                        <div class="flex-container centre" style="flex-grow: 5">
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/events-data" id="aid"> {{ $t('events.data.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('events.data.content') }}</p>

                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/events-videos" id="vieos"> {{ $t('events.videos.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('events.videos.content') }}</p>

                                            </div>
                                            <div class="mt-lg-5">
                                                <router-link class="dropdown-item" to="/pictures" id="eatingHabits" @click="scrolltop"> {{ $t('events.images.title') }} </router-link>
                                                <p class="content_navbar">{{ $t('events.images.content') }}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="nav-item" @click="hideMenu">
                                <router-link class="nav-link " to="/contact" id="contact" @click="scrolltop"> {{$t('contact')}} </router-link>
                            </li>
                        </ul>
                        <div class="social_media">
                            <a href="https://www.facebook.com/AlgeriaHCY/" target="_blank">
                                <img :src="'../images/icons/facebook.png'" height="24" alt="">
                            </a>
                            <a href="https://www.youtube.com/channel/UCTlPW0hVsuBNIhpMEAQGtKQ" target="_blank">
                                <img :src="'../images/icons/youtube.png'" height="24" alt="">
                            </a>
                            <a href="https://twitter.com/AlgeriaHCY" target="_blank">
                                <img :src="'../images/icons/twitter.png'" height="24" alt="">
                            </a>
                            <a href="https://www.instagram.com/Algeria.HCY/" target="_blank">
                                <img :src="'../images/icons/instagram.png'" height="24" alt="">
                            </a>
                            <a href="https://www.linkedin.com/company/algeriahcy/" target="_blank">
                                <img :src="'../images/icons/linkedin.png'" height="24" alt="">
                            </a>

                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data: function () {
            // console.log(window.location.pathname);
            const lang = localStorage.getItem('lang') || 'ar';
            if(lang === 'ar'){
                return {
                    lang: lang,
                    direction_ar: true,
                    rtl: 'rtl',
                    right: 0,
                }
            }else {
                return {
                    lang: lang,
                    direction: true,
                    rtl: 'ltr'
                }
            }
        },
        methods: {
            handleChange(event){
                let lang = event.target.value;
                localStorage.setItem('lang', event.target.value);
                window.location.reload();
                // this.$refs.direction.classList.remove('ms-auto');
                // this.$refs.direction.classList.add('ml-auto');

            },
            hideMenu(){
                let   menu = this.$el.querySelector('#main_nav');
                menu.classList.remove('show');
                window.scrollTo(0,0);
            },
            scrolltop(){
                window.scrollTo(0,0);
            }
        }
    }

    let doc = document.documentElement;
    if(doc.scrollTop > 0){
        let shared = this.document.querySelector('.shared');
        let element2 = this.document.querySelector('.top');
        let contain = this.document.querySelector('.container');

        element2.classList.add('shrink');
        shared.classList.add('fixed-top');
        contain.classList.add('body-setting');
    }
    window.onscroll = function () {
        let shared = this.document.querySelector('.shared');
        let element2 = this.document.querySelector('.top');
        let contain = this.document.querySelector('.container');

        if(window.scrollY >0){
            element2.classList.add('shrink');
            shared.classList.add('fixed-top');
            contain.classList.add('body-setting');
        }else {
            element2.classList.remove('shrink');
            shared.classList.remove('fixed-top');
            contain.classList.remove('body-setting');
        }
    }
</script>

<style scoped>
    .social_media img{
        margin-right: 8px;
    }
    .nav-logo img{
        /*width: 140px;*/
        height: 60px;
    }

    .content_navbar{
        /*font-family: DroidArabic!important;*/
        text-align: right!important;
        font-size: 13px!important;
        text-decoration: none!important;
        max-width: 150px;
        padding: 10px 0 10px 0!important;
    }


    @media screen and (min-width:991px){
        #accueil {
            margin-right: 30px;
        }
    }

    @media screen and (max-width:991px){
        .logo_mobile{
            height: 35px!important;
        }
        .social_media{
            text-align: center!important;
        }
        .bloc-dropdown{
            text-align: center;
            border: none;
        }
        .description-menu{
            display: none;
        }
        /*.nav-logo img{*/
            /*width: 110px;*/
        /*}*/

        .nav-item a{
            color: #60656c!important;
            font-size: 15px;
            /*font-family: DroidArabic !important;*/
            font-weight: 500;
            line-height: 2.2;
        }
        .nav-element{
            margin-top: 15px!important;
        }
        .dropdown-menu{
            border: none;
        }

        .navbar{
            border-bottom: lightgray 2px solid;
        }
        .nav-link{
            text-align: center;
            font-weight: bold!important;
        }
        .flex-container {
            background-color: #f5f5f59c;
            animation: rotateY 700ms ease-in-out forwards;
            transform-origin: top center;
        }
        .navbar-nav{
            width: 100%;
        }
        .navbar-toggler {
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            background-color: #22845B!important;
        }

        #accueil{
            margin: 0;
        }

        .dropdown-menu img,.dropdown-menu p{
            display: none;
        }

        .dropdown-menu a{
            text-align: center;
            font-weight: 500!important;
        }
        .dropdown-menu .flex-item{
            margin-top: 8px;
        }
        .dropdown-menu{
            animation: rotateMenu 1s;
        }
        .fixed-navbar{
            direction: rtl;
        }
        .align-mobile{
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .container{
            width: 98%!important;
        }
        li a.router-link-active{
            border-bottom: 2px solid red!important;
            background-color: transparent!important;
            width: 40%;
            margin-right: auto!important;
            margin-left: auto!important;
        }

    }
    @keyframes rotateMenu {
        0% {
            transform: rotateX(-90deg)
        }
        70% {
            transform: rotateX(20deg)
        }
        100% {
            transform: rotateX(0deg)
        }
    }
</style>

