<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > الصفحة غير موجودة </div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <h1 class="maintenance alt center">الصفحة غير موجودة</h1>
        <h1 class="maintenance alto center">404</h1>
    </div>

</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
    .alt{
          margin-top: 260px;
    }
    .alto{
        margin-bottom: 250px!important;
    }

    .maintenance{
        text-align: center;
        color: #343a40;
    }
    .center{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        width: 90%;
    }
</style>