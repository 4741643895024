<template>
    <div class="section wrapper" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('welcome.title') }}  > {{  $t('welcome.speechPresidentCouncil') }}</div>
        </div>
    </div>
    <div class="container mt-5 mb-5" dir="rtl">
        <div class="row back-gray">
            <div class="col-md-8">
                <p class="mb-2">
                    هي مؤسسة الشباب تطل بوجهها المتفائل على عالمها الخارجي لتعانق بحرقة صدق المسار المؤسساتي الذي تبنيه الدولة الجزائرية اليوم بكل عزم على هندسة واقع شبابياً جديد ليتلاحم مع مؤسسته ليٌبلِغ صوته المسموع من أجل ترقية العمل الشبابي في مختلف المجلات من خلال التمكين له ليكون بحق فاعلا في تشكيل رؤية وطنية تنتهج الاستثمار في عنفوان الشباب وقوته وقدرته لتحقيق الإقلاع الوطني.
                </p>
                <p class="mb-2">
                    إن الموقع الالكتروني للمجلس منصة هامة من منصات الانفتاح وتوثيق الصلة مع مختلف الجهات داخل الوطن وخارجه بما سيعزز من دون شك صورة هذه التجربة الفتية المتميزة التي أضحت محل اهتمام داخلي وخارجي سليم  وهي تعد واحدة من سمات وفاء رئيس الجمهورية السيد عبد المجيد تبون بتعهداته التي مضى يجسدها بكل روح عالية، وألتقطها شباب وطني بجودة عالية، وهاهو ذا يتفاعل معها
                </p>
                <p class="mb-2">
                    بلياقة كبيرة تنبؤ بحق عن مستقبل مسار جزائر جديدة مشرق يكون الشباب فيه فاعلا أساسيا واستثمار راباحا لتحقيق جوده الحياة للمواطن والشموخ والسؤدد للوطن.
                </p>
                <p class="mb-2">
                    من هنا مصرون في بداية المشوار على تعزيز أدوات الحوار والتواصل والاتصال بكل الوسائل والمناهج التكنولوجيا الحديثة التي ترسخ فكرة مؤسسة عمومية حيوية ومتواصلة بشكل فَعال، وهو التوجه الذي يتجلى واضحًا من خلال حرقة زملائي أعضاء المجلس وهم يجسدون أرقى صور التواصل بتعدد أشكاله ووحدة هدفه وبتكريس حقيقي للممارسات الفضلى التي تحقيق ذلك.
                </p>
                <p class="mb-5">
                    تحيا الجزائر ويحياو ولاد بلادي ويحياو بنات بلادي. والمجد والخلود لشهدائنا الأبرار.
                </p>
            </div>
            <div class="col-md-4">
                <a :href="'images/portrait_president_conseil.jpg'" target="_blank">
                    <img :src="'images/portrait_president_conseil.jpg'" class="img-fluid" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "presidentCouncil"
    }
</script>

<style scoped>
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
        text-align: justify;
        text-justify: inter-word;
    }
    .back-gray p{
        line-height: 200%;
    }
    @media (max-width: 900px) {
        .view_mobile{
            display: block!important;
        }
        .embed_pdf{
            display: none;
        }
    }
    @media (min-width: 900px) {
        .view_mobile{
            display: none!important;
        }
        .embed_pdf{
            display: block!important;
        }
    }
</style>