<template>
  <LoadingPage/>
  <Header />

  <!--<router-view class="view LoadingPage" name="LoadingPage"></router-view>-->
  <router-view class="view Carousel" name="Carousel"></router-view>
  <router-view class="view homeComponent" name="homeComponent"></router-view>
  <router-view class="view aboutCouncil" name="aboutCouncil"></router-view>
  <router-view class="view rolesCouncil" name="RolesCouncil"></router-view>
  <router-view class="view varietyCouncil" name="VarietyCouncil"></router-view>
  <router-view class="view speechPresident" name="speechPresident"></router-view>
  <router-view class="view speechPresidentCouncil" name="speechPresidentCouncil"></router-view>
  <router-view class="view Gallery" name="Gallery"></router-view>
  <router-view class="view specializedCommittees" name="specializedCommittees"></router-view>
  <router-view class="view membersCouncil" name="membersCouncil"></router-view>
  <router-view class="view textLegislation" name="textLegislation"></router-view>
  <router-view class="view adminCouncil" name="adminCouncil"></router-view>
  <router-view class="view contact" name="contact"></router-view>
  <router-view class="view presidentCouncil" name="presidentCouncil"></router-view>
  <router-view class="view subsCouncil" name="subsCouncil"></router-view>
  <router-view class="view visions" name="visions"></router-view>
  <router-view class="view data" name="data"></router-view>
  <router-view class="view videos" name="videos"></router-view>
  <router-view class="view advertisements" name="advertisements"></router-view>


  <router-view class="view Detail_article" name="Detail_article"></router-view>
  <router-view class="view President_articles" name="President_articles"></router-view>
  <router-view class="view Council_articles" name="Council_articles"></router-view>
  <router-view class="view PageNotFound" name="PageNotFound"></router-view>

  <Footer />

</template>

<script>

import Header from './components/Header.vue'
import Footer from './components/Footer'
import LoadingPage from './components/LoadingPage'

export default {
  name: 'App',
  components: {
      Header,
      LoadingPage,
      Footer
  }
}
</script>

