<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > {{ $t('council.rolesObjectivesCouncil.title') }}</div>
        </div>
    </div>
    <div class="container mt-5 mb-5" dir="rtl">
        <div class="title " >دور المجلس وأهدافه:</div>
        <div class="row back-gray">
            <div style="padding: 5px">
                <ul style="line-height:230%">
                    <li>إصدار مجلاّت ومنشورات في المواضيع ذات الصلة بمهامه؛</li>
                    <li>إبرام اتفاقيات واتفاقات التعاون مع مختلف الهيئات الوطنية والدولية في المواضيع ذات الصلة بمهامه واختصاصاته، وذلك طبقاً للأحكام التشريعية والتنظيمية المعمول بها؛</li>
                    <li>إعادة تنشيط العلاقات مع مؤسسات التعليم العالي والمجالس المنتخبة بما يسمح بترقية الحوار والتشاور قصد دراسة المواضيع ذات الصلة بمهامه واقتراح الحلول للإشكاليّات المطروحة.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Roles_council"
    }
</script>

<style scoped>
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }
</style>