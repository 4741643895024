<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > {{ $t('council.aboutCouncil.title') }}</div>
        </div>
    </div>
    <div class="container mb-5" dir="rtl">
        <div class="title " >{{ $t('council.aboutCouncil.title') }}</div>
        <div class="row back-gray mb-3">
            <p class="mb-2">
                المجلس الأعلى للشباب هو هيئة استشاريّة لدى رئيس الجمهورية، يتمتّع بالشخصية المعنوية والاستقلال المالي، يُقدّم المجلس آراء وتوصيات واقتراحات حول المسائل المتعلّقة بحاجات الشباب وازدهاره في المجالات الاقتصادية والاجتماعية والثقافية والرياضية، ويساهم في ترقية القيم الوطنية والضمير الوطني والحس المدني والتضامن الاجتماعي في أوساط الشباب، كما يشارك في تصميم المخطط الوطني للشباب وكذا السياسات والاستراتيجيات والبرامج والأجهزة العمومية المتعلقة بالشباب، وتشجيعهم على المشاركة في الحياة العامة والسياسية مع إشراكهم في التنمية في شتى أبعادها.
            </p>
            <p class="mb-2">
                يأخذ المجلس الأعلى للشباب الدور المنوط بهذه الهيئة امتدادات أخرى، كونه يشمل أيضا المشاركة في الوقاية من كل أشكال التمييز وخطابات الكراهية والجهوية والتطرف والآفات الاجتماعية في أوساط الشباب، ومكافحتها، مع المساهمة في تطوير وتحسين نوعية التربية والتعليم والتكوين لفائدة ذات الفئة، ويتم من خلال هذا المجلس إشراك الشباب في الإشعاع الثقافي للبلاد في تمجيد تاريخها وتقديم آراء حول مشاريع النصوص التشريعية والتنظيمية المتعلقة بالشباب.
            </p>
        </div>
        <div class="title mb-2">
            صلاحيات ودور المجلس :
        </div>
        <div class="row back-gray mb-3">
            <ul>
                <li>
                    إصدار مجلاّت ومنشورات في المواضيع ذات الصلة بمهامه؛
                </li>
                <li>
                    إبرام اتفاقيات واتفاقات التعاون مع مختلف الهيئات الوطنية والدولية في المواضيع ذات الصلة بمهامه واختصاصاته، وذلك طبقاً للأحكام التشريعية والتنظيمية المعمول بها؛
                </li>
                <li>
                    إعادة تنشيط العلاقات مع مؤسسات التعليم العالي والمجالس المنتخبة بما يسمح بترقية الحوار والتشاور قصد دراسة المواضيع ذات الصلة بمهامه واقتراح الحلول للإشكاليّات المطروحة.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About_council"
    }
</script>

<style scoped>
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
        text-align: justify;
        text-justify: inter-word;
    }
    .back-gray p{
        line-height: 200%;
    }
</style>