<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('council.title') }} > {{ $t('council.varietyCouncil.title') }}</div>
        </div>
    </div>
    <div class="container mb-5" dir="rtl">
        <div class="title " >تشكيلة المجلس:</div>

        <div class="row back-gray">
            <p>يتكوّن المجلس من ثلاثمائة وثمانية وأربعين (348) عضواً، يوزّعون كما يأتي:</p>
            <div style="padding: 5px">
                <ul style="line-height:200%">
                    <li>مائتان واثنان وثلاثون(232) عضواً منتخباً بعنوان تمثيل شباب الولايات، مناصفة رجل وامرأة، وفقاً للكيفيات المنصوص عليها في المادة 08 من المرسوم الرئاسي رقم 21-416 المؤرخ في 20 ربيع الأول عام 1443 الموافق 27 أكتوبر سنة 2021 والمذكور أعلاه؛</li>
                    <li>أربعة وثلاثون (34) عضواً بعنوان ممثلي المنظمات والجمعيات الشبانية أو الناشطة تجاه الشباب، المحلية والوطنية، مناصفة رجل وامرأة، يُعيّنهم الوزير المكلّف بالشباب؛</li>
                    <li>ستة عشر(16) عضواً بعنوان تمثيل شباب الجالية الوطنية المقيمة بالخارج، مناصفة رجل وامرأة، يُعيّنهم الوزير المكلّف بالشؤون الخارجية؛</li>
                    <li>ستة عشر(16) عضواً بعنوان تمثيل الطلبة والمنظمات الطلابية، مناصفة رجل وامرأة، يُعيّنهم الوزير المكلّف بالتعليم العالي؛</li>
                    <li>عشرة(10) أعضاء بعنوان تمثيل المتربّصين والممتهنين وتلاميذ التكوين المهني، مناصفة رجل وامرأة، يُعيّنهم الوزير المكلّف بالتكوين المهني؛</li>
                    <li>عشرة(10)أعضاء بعنوان تمثيل جمعيات الشباب ذوي الإعاقة، مناصفة رجل وامرأة، يُعيّنهم الوزير المكلّف بالتضامن الوطني؛</li>
                    <li>عشرة(10) أعضاء يُعيّنهم رئيس الجمهورية على أساس كفاءتهم وخبرتهم في المجالات المتعلّقة بالشباب؛</li>
                    <li>عشرون (20) عضواً بعنوان الحكومة والمؤسّسات العمومية المكلّفة بشؤون الشباب.</li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Variety_council"
    }
</script>

<style scoped>
    ul li{
        line-height: 2.5;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: Cairo,sans-serif;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
        font-family: Cairo,sans-serif!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
        text-align: justify;
        text-justify: inter-word;
    }
</style>